import { width } from "./dimensions";

const findMedian = (values) => {
    const sorted = values.sort((a, b) => a - b);
    const middleIndex = Math.floor(sorted.length / 2);
    if (sorted.length % 2) {
        return sorted[middleIndex];
    }
    return (sorted[middleIndex] + sorted[middleIndex - 1]) / 2;
};

export const addMedian = (chartContainer, { xScale, yScaleFlipped }) => {
    const medianContainer = chartContainer
        .selectAll(".mz-chart__median-line")
        .data([0])
        .enter()
        .append("g")
        .classed("mz-chart__median-line", true);

    medianContainer
        .append("line")
        .attr("x1", 0)
        .attr("x2", width)
        .attr("y1", yScaleFlipped(0))
        .attr("y2", yScaleFlipped(0));

    medianContainer
        .append("text")
        .classed("mz-chart__median-label", true)
        .attr("x", 10)
        .attr("y", yScaleFlipped(0) - 15)
        .text("Медиана");
};

export const updateMedian = (chartContainer, { yScaleFlipped }, data) => {
    const median = findMedian(data);
    chartContainer
        .select(".mz-chart__median-line line")
        .transition()
        .attr("y1", yScaleFlipped(median))
        .attr("y2", yScaleFlipped(median));
    chartContainer
        .select(".mz-chart__median-label")
        .transition()
        .attr("y", yScaleFlipped(median) - 15);
};
