import { addLogo as addLogoBase } from "./logo";
import {
    addHeader as addHeaderBase,
    addMonths as addMonthsBase,
    addValues as addValuesBase,
} from "./labels";
import {
    addMedian as addMedianBase,
    updateMedian as updateMedianBase,
} from "./median";

import { addBars as addBarsBase, updateBars as updateBarsBase } from "./bars";

import { getScales, baseWidth, padding } from "./dimensions";

export const addLogo = (root) =>
    addLogoBase(root, 150, baseWidth - 150 - padding, 30);

export const addHeader = (root, text) => addHeaderBase(root, padding, 50, text);

const addMonths = (chartContainer) =>
    addMonthsBase(chartContainer, getScales(), padding);

const addValues = (chartContainer) =>
    addValuesBase(chartContainer, getScales(), padding);

const addMedian = (chartContainer) =>
    addMedianBase(chartContainer, getScales());

export const updateMedian = (chartContainer, data) =>
    updateMedianBase(chartContainer, getScales(), data);

const addBars = (chartContainer) => addBarsBase(chartContainer, getScales());

export const updateBars = (chartContainer, data) =>
    updateBarsBase(chartContainer, getScales(), data);

export const buildChart = (chartContainer) => {
    addValues(chartContainer);
    addBars(chartContainer);
    addMonths(chartContainer);
    addMedian(chartContainer);
};
