import { select, pointer } from "d3-selection";
import { getScales } from "./dimensions";

const tooltip = select(".mz-chart__tooltip");

const { xScale } = getScales();

const mouseover = function (event, d) {
    tooltip.style("display", "block");
    select(this).style("opacity", 1);
};

const mousemove = function (event, d) {
    tooltip
        .style("left", `${pointer(event, d)[0] + 10 - xScale.bandwidth()}px`)
        .style("top", `${pointer(event, d)[1] + 10}px`);
    tooltip.select(".mz-chart__tooltip-header").text(d.label);
    tooltip.select(".mz-chart__tooltip-value").text(d.value);
};

const mouseleave = function (event, d) {
    tooltip.style("display", "none");
    select(this).style("opacity", 0.9);
};

export const addTooltip = (bars) => {
    bars.on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave);
};
