import { yDomain } from "./constants";
import { width } from "./dimensions";

export function addHeader(root, x, y, content) {
    return root
        .append("text")
        .attr("text-anchor", "start")
        .attr("x", x)
        .attr("y", y)
        .selectAll("tspan")
        .data(content.split("\n"))
        .join("tspan")
        .classed("mz-chart__header", true)
        .attr("x", x)
        .attr("dy", (d, i) => `${i ? 1.2 : 0}em`)
        .text((d) => d);
}

export const addMonths = (
    chartContainer,
    { yScaleFlipped, xScale },
    padding
) => {
    chartContainer
        .selectAll(".mz-chart__bar")
        .append("text")
        .classed("mz-chart__label-horizontal", true)
        .attr("x", (d) => xScale(d.label) + 0.5 * xScale.bandwidth())
        .attr("y", () => yScaleFlipped(0) + padding / 2)
        .text((d) => d.label);
};

export const addValues = (
    chartContainer,
    { yScaleFlipped, xScale },
    padding
) => {
    const [minY, maxY] = yDomain.slice(0).sort((a, b) => a - b);
    const values = [...Array(5).keys()].map(
        (i) => minY + (i / 4) * (maxY - minY)
    );
    const verticalValueGroups = chartContainer
        .append("g")
        .selectAll(".mz-chart__label-vertical")
        .data(values)
        .enter()
        .append("g")
        .classed("mz-chart__label-vertical", true);

    verticalValueGroups
        .append("text")
        .attr("x", -padding / 2)
        .attr("y", (d) => yScaleFlipped(d))
        .text((d) => d);

    verticalValueGroups
        .append("line")
        .classed("mz-chart__grid-line", true)
        .attr("x1", 0)
        .attr("x2", width)
        .attr("y1", (d) => yScaleFlipped(d))
        .attr("y2", (d) => yScaleFlipped(d));
};
