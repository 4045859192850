import { addTooltip } from "./tooltip";
import { getData } from "./dimensions";

export const addBars = (chartContainer, { xScale, yScale, yScaleFlipped }) => {
    const bars = chartContainer
        .selectAll(".mz-chart__bar")
        .data(getData())
        .enter()
        .append("g")
        .classed("mz-chart__bar", true)
        .append("rect")
        .attr("x", (d) => xScale(d.label))
        .attr("y", () => yScaleFlipped(0))
        .attr("height", () => yScale(0))
        .attr("width", () => xScale.bandwidth());

    addTooltip(bars);
};

export const updateBars = (
    chartContainer,
    { xScale, yScale, yScaleFlipped, colorScale },
    data
) => {
    const selection = chartContainer.selectAll(".mz-chart__bar rect");
    selection.data(data);
    selection
        .transition()
        .attr("x", (d, i) => xScale(d.label))
        .attr("y", (d, i) => yScaleFlipped(d.value))
        .attr("height", (d, i) => yScale(d.value))
        .attr("fill", (d) => colorScale(d.value));
};
