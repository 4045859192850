import { select } from "d3-selection";

const logo =
    '<path d="M26 52.4751H33.2292V37.4394L38.9553 52.4751H41.6752L47.5086 37.4394V52.4751H55.5609V27H45.254L40.852 39.3634L36.5217 27H26V52.4751Z" fill="white"/>\n' +
    '<path d="M68.7396 52.9382C74.6804 52.9382 77.9371 50.4442 78.5097 46.1686H71.9247C71.71 47.2375 70.9584 48.1639 68.9901 48.1639C66.9859 48.1639 65.6618 46.9525 65.4829 44.7078H78.4739V42.9976C78.4739 36.2993 74.0004 33.3064 68.7396 33.3064C63.0135 33.3064 58.361 37.0475 58.361 43.1045V43.3895C58.361 49.5534 62.763 52.9382 68.7396 52.9382ZM65.5186 41.038C65.8049 38.9359 67.0217 37.8314 68.7396 37.8314C70.6363 37.8314 71.6026 39.0428 71.6742 41.038H65.5186Z" fill="white"/>\n' +
    '<path d="M88.3341 43.2827C88.4056 41.9644 88.4772 40.3967 88.513 38.6508H91.9844V47.5938H87.4394C87.9046 46.4537 88.1909 45.0285 88.3341 43.2827ZM85.7215 52.4751H95.2769L95.3485 57H101.432V47.5938H98.9273V33.8409H82.6796C82.6796 37.867 82.608 40.753 82.429 42.7126C82.1427 45.6698 81.5343 47.095 80.5323 47.5938H79.566V57H85.65L85.7215 52.4751Z" fill="white"/>\n' +
    '<path d="M110.374 52.4751L115.671 42.1069V52.4751H122.65V33.8409H115.778L110.482 44.2447V33.8409H103.503V52.4751H110.374Z" fill="white"/>\n' +
    '<path d="M133.99 48.2352C132.701 48.2352 132.021 47.6651 132.021 46.7743C132.021 45.4561 132.88 44.9929 135.242 44.9929H136.745V45.9549C136.745 47.3444 135.6 48.2352 133.99 48.2352ZM131.449 52.9382C134.491 52.9382 136.137 51.62 136.924 50.3373V52.4751H143.724V40.4323C143.724 35.5154 140.431 33.3064 134.992 33.3064C129.623 33.3064 126.045 35.6223 125.758 40.1829H132.343C132.451 39.2209 132.952 38.1877 134.562 38.1877C136.459 38.1877 136.745 39.3278 136.745 40.8955V41.2162H135.135C128.657 41.2162 125.078 42.9264 125.078 47.3088C125.078 51.2993 128.084 52.9382 131.449 52.9382Z" fill="white"/>\n' +
    '<path d="M163.549 38.4014C163.549 35.5154 160.543 33.3064 155.103 33.3064C150.415 33.3064 146.729 35.6223 146.192 40.2898H152.312C152.634 39.0071 153.493 38.2589 154.781 38.2589C156.069 38.2589 156.535 38.7934 156.535 39.5416V39.6485C156.535 40.5036 155.926 41.038 154.745 41.038H152.884V44.8504H154.888C156.356 44.8504 156.928 45.4204 156.928 46.3824V46.4893C156.928 47.5938 156.248 48.2352 154.996 48.2352C153.385 48.2352 152.491 47.38 152.061 45.7767H145.87C146.514 50.1948 149.413 52.9382 155.175 52.9382C160.901 52.9382 164.014 50.3373 164.014 47.0238V46.9169C164.014 44.4584 162.404 43.1045 159.791 42.7126C162.225 42.2494 163.549 40.9311 163.549 38.5083V38.4014Z" fill="white"/>\n' +
    '<path d="M176.07 52.9382C181.904 52.9382 186.485 49.3397 186.485 43.2114V42.9264C186.485 36.9406 181.94 33.342 176.106 33.342C170.201 33.342 165.656 37.0475 165.656 43.1045V43.3895C165.656 49.5178 170.237 52.9382 176.07 52.9382ZM176.106 47.772C173.852 47.772 172.778 46.2043 172.778 43.1758V42.8907C172.778 39.9691 173.959 38.5083 176.106 38.5083C178.289 38.5083 179.327 40.076 179.327 42.9976V43.247C179.327 46.2043 178.253 47.772 176.106 47.772Z" fill="white"/>\n' +
    '<path d="M200.906 52.4751H207.921V33.8409H200.906V40.6105H196.003V33.8409H188.989V52.4751H196.003V45.4917H200.906V52.4751Z" fill="white"/>\n' +
    '<path d="M219.266 48.2352C217.977 48.2352 217.297 47.6651 217.297 46.7743C217.297 45.4561 218.156 44.9929 220.518 44.9929H222.021V45.9549C222.021 47.3444 220.876 48.2352 219.266 48.2352ZM216.725 52.9382C219.767 52.9382 221.413 51.62 222.2 50.3373V52.4751H229V40.4323C229 35.5154 225.708 33.3064 220.268 33.3064C214.9 33.3064 211.321 35.6223 211.034 40.1829H217.619C217.727 39.2209 218.228 38.1877 219.838 38.1877C221.735 38.1877 222.021 39.3278 222.021 40.8955V41.2162H220.411C213.933 41.2162 210.354 42.9264 210.354 47.3088C210.354 51.2993 213.361 52.9382 216.725 52.9382Z" fill="white"/>';

export const addLogo = (root, width, x, y) => {
    const fill = "#000";
    const bg = 255;
    const scaleFactor = width / bg;
    root.append("g")
        .classed("mz_chart-logo", true)
        .attr("transform", `translate(${x} ${y}) scale(${scaleFactor})`)
        .html(`<rect width=${bg} height="${84}" fill="${fill}"/>\n` + logo);
    root.append("g")
        .classed("mz-chart__refresh", true)
        .attr(
            "transform",
            `translate(${x} ${y}) scale(${scaleFactor}) translate(${
                -84 - 21
            } 0)`
        )
        .attr("fill", fill);
    const refreshButton = select(".mz-chart__refresh");
    refreshButton.append("rect").attr("width", 84).attr("height", 84);
    refreshButton
        .append("path")
        .attr("transform", "translate(18 18) scale(2 2)")
        .attr(
            "d",
            "M19.91,15.51H15.38a1,1,0,0,0,0,2h2.4A8,8,0,0,1,4,12a1,1,0,0,0-2,0,10,10,0,0,0,16.88,7.23V21a1,1,0,0,0,2,0V16.5A1,1,0,0,0,19.91,15.51ZM12,2A10,10,0,0,0,5.12,4.77V3a1,1,0,0,0-2,0V7.5a1,1,0,0,0,1,1h4.5a1,1,0,0,0,0-2H6.22A8,8,0,0,1,20,12a1,1,0,0,0,2,0A10,10,0,0,0,12,2Z"
        )
        .attr("fill", "white");
};
