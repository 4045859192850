import { select } from "d3-selection";
import { getData, padding } from "./components/dimensions";
import {
    addLogo,
    addHeader,
    buildChart,
    updateMedian,
    updateBars,
} from "./components/addElement";
import "d3-transition";

const root = select(".mz-chart");

addLogo(root);
addHeader(root, "Невероятно информативный\nслучайный график");

const chartContainer = root
    .append("g")
    .classed("mz-chart__container", true)
    .attr("transform", `translate(${padding * 2}, ${100 + padding})`);

buildChart(chartContainer);

const drawChart = () => {
    const data = getData();
    updateBars(chartContainer, data);
    updateMedian(
        chartContainer,
        data.map(({ value }) => value)
    );
};

drawChart();

select(".mz-chart__refresh").on("click", drawChart);
