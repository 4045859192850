import { xDomain, yDomain, colors } from "./constants";
import { randomInt } from "d3-random";
import { scaleLinear, scaleBand } from "d3-scale";

export const baseWidth = 960;
export const padding = 30;

export const width = baseWidth - padding * 3;
export const height = 500 - padding;
export const randomValue = randomInt(...yDomain);

export const getScales = () => {
    const yScale = scaleLinear().domain(yDomain).range([0, height]);
    const yScaleFlipped = scaleLinear().domain(yDomain).range([height, 0]);
    const xScale = scaleBand()
        .domain(xDomain)
        .range([0, width])
        .paddingInner(0.2);
    const colorScale = scaleLinear().domain(yDomain).range(colors);
    return { yScale, xScale, colorScale, yScaleFlipped };
};

export const getData = () =>
    xDomain.map((m) => ({
        label: m,
        value: randomValue(),
    }));
